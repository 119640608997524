//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "SectionStories",
  components: {
    Section: () => import("@/components/common/Section/index"),
    SliderWithBullets: () => import("@/components/common/SliderWithBullets"),
    Scroller: () => import("@/components/common/Scroller/index"),
    StoryCard: () => import("@/components/common/StoryCard/index"),
  },
  props: {
    background: {
      type: String,
      default: () => "var(--dark-color)"
    },
    titleColor: {
      type: String,
      default: () => "var(--white)"
    },
    bulletsTheme: {
      type: String,
      default: () => "light"
    },
    disableMarginTop: {
      type: Boolean,
      default: () => false
    },
    disableMarginBottom: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapGetters({
      stories: "stories/stories",
      pending: "stories/pending",
      city: "cities/default",
    }),
    show() {
      return this?.stories?.length > 0 && !this?.pending
    },
    titleStyle() {
      return `color: ${this.titleColor}`;
    }
  },
  mounted() {
    this.$store.dispatch("stories/fetch", {
      params: {
        resolution: "micro",
        lang: this.$i18n.locale,
        city_id: this.$config.singleCity ? this.city.id : null,
        count: 100500,
      }
    })
  }
};
