//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'StoryCard',
  components: {
    ImageComponent: () => import('@/components/common/ImageComponent')
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    entity: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    image: {
      type: String,
      default: () => require('@/assets/images/default-image.svg')
    }
  },
  computed: {
    link() {
      return `/stories/${this.id}`
    }
  }
}
